import {makeStyles} from "@material-ui/styles";
import {ChevronRight} from "@material-ui/icons";
import {Button, TextField, Chip, CircularProgress} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";
import {getCMSSettings} from "tools/cmsBlockSettings";
import {useContext, useEffect, useState} from "react";
import fetch from "react-storefront/fetch";
import {useTranslation} from "tools/i18n";
import { ErrorContext } from "../../../providers/error";
import useSessionStore from '@services/stores/SessionStore';

const useStyles = makeStyles((theme) => {
    return {
        wrapper: {},
        formsList: {
            display: 'flex',
            flexDirection: 'column'
        },
        alertRoot: {
            alignItems: 'center'
        },
        form: {
            width: '100%',
            padding: 14,
            margin: '0 0 10px 0',
            fontSize: 14,
            fontWeight: 400,
            color: '#5a5a5a',
            appearance: 'none',
            cursor: 'pointer',
            fontFamily: '"Open Sans", sans-serif',
            backgroundColor: theme.palette.primary.background,
            boxShadow: '3px 3px 3px rgba(50, 50, 93, 0.04)',
            '& label': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
                transition: 'transform 0.25s ease'
            },
            '&:hover label': {
                transform: 'translateX(10px)'
            }
        },
        back: {
            margin: '15px 0',
            minWidth: 125,
            fontWeight: 500,
            textTransform: 'none',
            backgroundColor: '#e0e0e0'
        },
        selectedForm: {
            padding: 16,
            backgroundColor: theme.palette.primary.background,
            boxShadow: '3px 3px 3px rgba(50, 50, 93, 0.04)',
        },
        field: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: 10,
            fontSize: 14
        },
        inputLabel: {
            fontSize: 14
        },
        fileInputLabel: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '8px 0',
            marginTop: 20,
            '& p': {
                marginTop: 0,
                marginBottom: 5
            }
        },
        fileInput: {
            display: 'none'
        },
        files: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: 5,
            marginTop: 5
        },
        textArea: {
            lineHeight: 1.4
        },
        submit: {
            marginTop: 16,
            padding: 14,
            color: theme.palette.primary.background,
            backgroundColor: '#24b47e',
            textTransform: 'none',
            '&:hover': {
                backgroundColor: '#2f9971'
            }
        },
        submitLoader: {
            marginLeft: 5
        },
        submitSuccess: {
            marginTop: 15,
            marginBottom: 10
        },
        supportText: {
            textAlign: 'center',
            fontWeight: 300
        },
        supportTextWrapper: {
            maxWidth: '500px',
            margin: '25px 0',
        }
    }
}, {name: 'ZendeskForm'});

export default function ZendeskForm({block, category}) {
    const classes = useStyles();
    const {t} = useTranslation(['support', 'popup']);
	const CMSSettings = getCMSSettings(block);
    const {actions: errorActions} = useContext(ErrorContext);
	const [{forms, fields, selectedForm}, setFormData] = useState({forms: false, fields: false, selectedForm: false});
	const [fieldData, setFieldData] = useState({});
	const [submitSuccess, setSubmitSuccess] = useState(false);
	const [submitLoader, setSubmitLoader] = useState(false);
	const formsWithSubjectField = [360000195498, 360000195438];
	const subjectFieldId = 360008251477;
	const descriptionFieldId = 360008251497;

    const {
        translated: {
            name
        }
    } = useSessionStore((state) => state.session.salesChannel);

	useEffect(async () => {
        const response = await fetch('/api/zendesk/get/ticketforms', {
			method: 'get',
			headers: {
				'Content-Type': 'application/json',
			},
            credentials: 'include'
        });

        let result = await response.json();
        if (result.status === 'success') {
            setFormData({...result.data, selectedForm: false})
        }
    }, []);

	function selectForm(form) {
	    setFormData({forms: forms, fields: fields, selectedForm: form});
    }

    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    async function submitTicket() {
	    let ticketFormData = new FormData();

        Object.keys(fieldData).forEach((key) => {
            if(key === 'attachments') {
                fieldData[key].forEach((file) => {
                    ticketFormData.append('files', file);
                });
            } else {
                ticketFormData.append(key, fieldData[key]);
            }
        });

        ticketFormData.append('formType', selectedForm.name);
        ticketFormData.append('salesChannel', name);

	    const response = await fetch('/api/zendesk/post/' + selectedForm.id, {
			method: 'post',
            credentials: 'include',
            body: ticketFormData
        });

        if(response.status === 200) {
            selectForm(false);
            clearFieldData();
            errorActions.addError({
				type: 'success',
				messageKey: 'support-success-text',
				messageData: {}
			});
        }
    }

    function clearFieldData() {
        setFieldData({});
    }

    function updateFieldData(input, event) {
	    let value = event.target.value;
	    let newFieldData = {...fieldData};
	    newFieldData[input] = value;

        setFieldData(newFieldData);
    }

    function updateFileAttachment(event) {
        let files = event.target.files;

        setFieldData({...fieldData, 'attachments': files});
    }

    function getFileAttachments() {
	    let fileNames = [];
        fieldData.attachments.forEach((file) => {
            fileNames.push(file.name);
        });

        return fileNames;
    }

    function getSupportText(id) {
        const key = id + '-support-text';
        const text = t(key);

        if (text === key) {
            return '';
        }

        if((name === 'Newport EU' || name === 'Newport NL' || name === 'Newport Global' || name === 'Newport GB') && selectedForm.id === 360000185697) {
            return '';
        }

        return <div className={classes.supportTextWrapper}>
            {
                text.split('\n').map(function(item, key) {
                    return <span key={key} dangerouslySetInnerHTML={{__html: item + '<br>'}} />
                })
            }
        </div>
    }

    return (
        <div className={classes.wrapper} style={{
				...CMSSettings.styles,
        }}>
            {!selectedForm ? <div className={classes.formsList}>
                {submitSuccess ? <div className={classes.submitSuccess}>
                    <Alert severity="success" onClose={() => {setSubmitSuccess(false);}}>
                        <AlertTitle>{t('support-success-title')}</AlertTitle>
                        {t('support-success-text')}
                    </Alert>
                </div> : ''}

                <h3>{t('support-title')}</h3>

                <Alert severity="warning" classes={{root: classes.alertRoot}} style={{marginBottom: 20}}><p dangerouslySetInnerHTML={{__html: t('support-notice') + ' ' + t('popup:chat-disclaimer')}}/></Alert>

                {forms ? forms.map((form, index) => {
                    return (
                        <div className={classes.form} onClick={() => selectForm(form)} key={form.id}>
                            <label>{t(form.display_name)} <ChevronRight/></label>
                        </div>
                    );
                }) : ''}
            </div> : ''}

            {selectedForm ? <>
                <Button onClick={() => {selectForm(false); clearFieldData();}} className={classes.back}>{t('support-back')}</Button>
                <div className={classes.selectedForm}>
                    <h3>{t(selectedForm.display_name)}</h3>
                    {getSupportText(selectedForm.id)}
                    <div className={classes.formFields}>
                        <div className={[classes.field, classes.requiredField].join(' ')}>
                            <TextField label={t('support-email')} variant="outlined"
                                       InputLabelProps={{className: classes.inputLabel}}
                                       className={classes.textField}
                                       onChange={(event) => {updateFieldData('email', event)}}
                                       required={true}
                                       error={!validateEmail(fieldData['email'])}
                            ></TextField>
                        </div>

                        <div className={[classes.field, classes.requiredField].join(' ')}>
                            <TextField label={t('support-name')} variant="outlined" InputLabelProps={{className: classes.inputLabel}} onChange={(event) => {updateFieldData('name', event)}} required={true}></TextField>
                        </div>

                        {selectedForm.ticket_field_ids.map((id) => {
                            if(!fields[id] || (id === subjectFieldId && formsWithSubjectField.indexOf(selectedForm.id) < 0)) {
                                return;
                            }

                            let field = fields[id];

                            return <div className={[classes.field, classes.requiredField].join(' ')} key={id}>
                                <TextField label={t(field.title)} variant="outlined" inputProps={{className: classes.textArea}} InputLabelProps={{className: classes.inputLabel}} InputProps={{style: {backgroundColor: theme.palette.primary.background}}} multiline={field.type === 'description'} rows={3} rowsMax={6} onChange={(event) => {updateFieldData(field.id, event)}} required></TextField>
                                {field.id === descriptionFieldId ? <Alert severity="info" style={{marginTop: -4}}>{t('support-description')}</Alert> : ''}
                            </div>;
                        })}

                        {selectedForm.id === 360000195478 ?
                            <label className={classes.fileInputLabel}>
                                <p>{t('support-upload-title')}<span>*</span></p>
                                <div className={classes.uploadWrapper}>
                                    <Button variant="contained" color="primary" component="span">
                                        {t('support-upload')}
                                    </Button>

                                    {fieldData.attachments ?
                                        <div className={classes.files}>
                                            {getFileAttachments().map((file, index) => {
                                                return <Chip label={file} className={classes.file} key={index}/>
                                            })}
                                        </div>
                                    : ''}
                                </div>
                                <input accept="image/*" className={classes.fileInput} type="file" multiple={true} onChange={updateFileAttachment}/>
                            </label>
                        : ''}

                        <Button fullWidth={true} className={classes.submit} onClick={submitTicket}>
                            {t('support-submit')}
                            {submitLoader ? <CircularProgress size={24} color="inherit" className={classes.submitLoader}/> : ''}
                        </Button>
                    </div>
                </div>
            </> : ''}

            <p className={classes.supportText}>{t('support-additional')}</p>
        </div>
    );
}
