import {makeStyles} from "@material-ui/styles";
import {getCMSSettings} from "tools/cmsBlockSettings";
import { Button, Checkbox, CircularProgress, Grid, MenuItem, Select, TextField } from "@material-ui/core";
import PhoneInput from 'react-phone-number-input/input';
import { getCountryCallingCode, isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import {useTranslation} from "tools/i18n";
import { useEffect, useState } from "react";
import {Alert} from "@material-ui/lab";
import theme from "../../../theme";
import Flags from "react-phone-number-input/modules/Flag";
import globalCountries from 'functions/globalCountries.json';
import useSessionStore from '@services/stores/SessionStore';
import useUserStore from '@services/stores/UserStore';

const useStyles = makeStyles({
	wrapper: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%'
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: 20,
		width: '100%',
		maxWidth: 800
	},
	inputGroup: {
		display: 'flex',
		flexDirection: 'column',
		gap: 20,
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row'
		}
	},
	input: {
		width: '100%',
		'& input': {
			padding: '14px 16px 16px',
			color: theme.palette.primary.lowlight,
			'&::-webkit-input-placeholder': {
				opacity: 1,
				fontWeight: 300
			}
		},
		[theme.breakpoints.up('md')]: {
			width: 'calc(50% - 10px)',
		}
	},
	outline: {
		borderColor: theme.palette.primary.color,
		borderRadius: 3
	},
	label: {
		top: -3
	},
	helperText: {
		margin: 0,
		paddingLeft: 15
	},
	signUp: {
		padding: 10,
		minWidth: 250,
		fontSize: 16,
		fontWeight: 600,
		color: theme.palette.primary.background,
		backgroundColor: 'black',
		'&:hover': {
			backgroundColor: '#000000'
		}
	},
	conditions: {
		marginTop: 0,
		fontSize: 16,
		textAlign: 'center'
	},
	checkbox: {
		color: 'black !important'
	}
}, {name: 'CustomerSignup'});

function PhoneNumberCustom(props) {
    const { component: Component, inputRef, onChange, country, ...other } = props;

    return (
        <>
            <Component
                {...other}
                country={country}
                international={true}
                withCountryCallingCode={true}
                getInputRef={inputRef}
                onChange={onChange}
            />
        </>
    );
}

export default function CustomerSignup({block}) {
	const classes = useStyles();
	const { config, salesChannel: { countryId, id }, shippingLocation, geoIpCountry, countries } = useSessionStore((state) => state.session);;
	const myNewportService = useUserStore((state) => state.service);
	const salesChannelId = id;
	const [phoneNumberCountry, setPhoneNumberCountry] = useState(geoIpCountry || shippingLocation?.country?.iso || false);
	const [formData, setFormDate] = useState({
		mobilePhone: ''
	});
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const CMSSettings = getCMSSettings(block);
	const {t} = useTranslation(['support', 'popup', 'errors']);

	useEffect(() => {
		if(shippingLocation && !phoneNumberCountry) {
			setPhoneNumberCountry(shippingLocation?.country?.iso);
		}
	}, [phoneNumberCountry, shippingLocation]);

	useEffect(() => {
		let parsedPhoneNumber = parsePhoneNumber(formData?.mobilePhone || '');

        if (phoneNumberCountry && (formData?.mobilePhone === '' || !parsedPhoneNumber)) {
            updateFormData('mobilePhone', { target: { value: '+' + getCountryCallingCode(phoneNumberCountry) } });
        } else if (phoneNumberCountry && parsedPhoneNumber.country !== phoneNumberCountry) {
            updateFormData('mobilePhone', { target: { value: '+' + getCountryCallingCode(phoneNumberCountry) + parsedPhoneNumber.nationalNumber } });
        }
	}, [phoneNumberCountry]);

	function updateFormData(input, event) {
	    let value = event.target.value;
	    let newFormData = {...formData};
	    newFormData[input] = value;

        setFormDate(newFormData);
    }

	function checkRequired() {
		const requiredFields = ['email', 'mobilePhone'];
		let validation = true;

		requiredFields.forEach((requiredField) => {
			let fieldValue = formData[requiredField];

			if(typeof fieldValue === 'undefined' || fieldValue.length === 0) {
				validation = false;
			}
		});

		return validation;
	}

	function getSalesChannelCountry() {
        let defaultCountry = countries.filter((country) => {
            return country.id === countryId;
        });

        return defaultCountry.length > 0 ? defaultCountry[0].iso : (countries.length > 0 ? countries[0].iso : 'SE');
    }

	async function submit() {
		if(!checkRequired()) {
			return;
		}

		if (salesChannelId) {
			formData.salesChannelId = salesChannelId;
		}

		const discoveryKey = myNewportService.getLocalDiscoveryKey();
		if (discoveryKey) {
			formData.discoveryKey = discoveryKey;
		}

		const countryCode = geoIpCountry || shippingLocation?.country?.iso
		if (countryCode) {
			formData.countryCode = countryCode;
		}

		try {
			setLoading(true);

			let response = await fetch('/api/voyado/signup', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(formData)
			});

			let registrationData = await response.json();

			if(registrationData.status === 'error') {
				if(registrationData.message.indexOf('Member exists') >= 0) {
					setError(t('errors:signup-already-exists'));
				} else if(registrationData.message.indexOf('Request is invalid') >= 0) {
					setError(t('errors:signup-request-invalid'));
				} else {
					setError(registrationData.message);
				}
			} else {
				setError(false);
				setSuccess(true);
			}
			setLoading(false);
		} catch (e) {
			setError(t('errors:signup-general-error'));

			setLoading(false);
		}
	}

	function shouldShowSocialSecurity() {
		if(config.language.locale === 'sv') {
			return true
		}

		return false;
	}

	return (
		<div id={"signup-form"} className={classes.wrapper} style={{
				...CMSSettings.styles,
			}}>
			{!success ? <Grid className={classes.form} container>
				<Grid className={classes.inputGroup} item>
					<TextField
						className={classes.input}
						InputProps={{ disableUnderline: true, classes: { notchedOutline: classes.outline } }}
						InputLabelProps={{ className: classes.label }}
						onChange={(event) => {
							updateFormData('firstName', event);
						}}
						type="text"
						label={t('first-name')}
						variant="outlined"/>

					<TextField
						className={classes.input}
						InputProps={{ disableUnderline: true, classes: { notchedOutline: classes.outline } }}
						InputLabelProps={{ className: classes.label }}
						onChange={(event) => {
							updateFormData('lastName', event);
						}}
						type="text"
						label={t('last-name')}
						variant="outlined"/>
				</Grid>

				<Grid className={classes.inputGroup} item>
					{shouldShowSocialSecurity() ? <TextField
						className={classes.input}
						InputProps={{ disableUnderline: true, classes: { notchedOutline: classes.outline } }}
						inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
						InputLabelProps={{ className: classes.label }}
						FormHelperTextProps={{ className: classes.helperText }}
						onChange={(event) => {
							updateFormData('socialSecurityNumber', event);
						}}
						type="text"
						label={t('personal-number')}
						helperText={'10 eller 12 siffror utan bindestreck'}
						variant="outlined"/> :
					<TextField
						className={classes.input}
						InputProps={{ disableUnderline: true, classes: { notchedOutline: classes.outline } }}
						inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
						InputLabelProps={{ className: classes.label }}
						FormHelperTextProps={{ className: classes.helperText }}
						onChange={(event) => {
							updateFormData('birthDay', event);
						}}
						type="text"
						label={t('birthday')}
						helperText={t('birthday-format')}
						variant="outlined"/>}

					<TextField
						className={[classes.input].join(' ')}
						InputProps={{
							classes: { notchedOutline: classes.outline, input: classes.outlinedInputInput },
							inputComponent: PhoneNumberCustom,
							inputProps: {
								component: PhoneInput,
								country: getSalesChannelCountry()
							},
							startAdornment: <Select
								className={classes.phoneCountrySelector}
								classes={{ select: classes.phoneCountryInput, icon: classes.phoneInputIcon }}
								value={phoneNumberCountry || geoIpCountry}
								onChange={(event) => {
									setPhoneNumberCountry(event.target.value);
								}}
								variant="standard"
								disableUnderline={true}
							>
								{shippingLocation ? Object.keys(globalCountries).map((countryCode) =>
									<MenuItem className={classes.menuItem} value={countryCode} key={countryCode}>
										<Flags country={countryCode !== 'GB' ? countryCode.toUpperCase() : 'GBR'}
											   flagUrl={'/images/flags/s/{XX}.svg'}
											   style={{ width: 20, marginRight: 8 }}/>
										<span className={classes.phoneInputCode}>{countryCode}</span>
									</MenuItem>
								) : <MenuItem value={0} key={'nothing'}>No country selected</MenuItem>}
							</Select>
						}}
						InputLabelProps={{ className: classes.label, shrink: true }}
						onChange={(value) => {
							if (value !== '') {
								updateFormData('mobilePhone', { target: { value } });
							}
						}}
						value={formData?.mobilePhone || ''}
						error={formData?.mobilePhone && parsePhoneNumber(formData?.mobilePhone || '')?.nationalNumber ? !isValidPhoneNumber(formData?.mobilePhone) : false}
						type="text"
						label={t('phone-number')}
						required={true}
						variant="outlined"/>
				</Grid>

				<Grid className={classes.inputGroup} item>
					<TextField
						className={classes.input}
						InputProps={{ disableUnderline: true, classes: { notchedOutline: classes.outline } }}
						InputLabelProps={{ className: classes.label }}
						onChange={(event) => {
							updateFormData('email', event);
						}}
						type="text"
						label={t('support-email')}
						variant="outlined"/>
				</Grid>

				{error ? <Grid item>
					<Alert severity="error" style={{ marginBottom: -20 }}><span
						dangerouslySetInnerHTML={{ __html: error }}/></Alert>
				</Grid> : ''}

				<Grid className={classes.inputGroup} style={{ justifyContent: 'center', marginTop: 40 }} item>
					<Button className={classes.signUp} onClick={() => {
						submit();
					}}>
						{t('customer-signup-button')}
						{loading ? <CircularProgress size={14} color={'inherit'} style={{ marginLeft: 8 }} disableShrink/> : ''}
					</Button>
				</Grid>

				<Grid className={classes.inputGroup} style={{ justifyContent: 'center', marginTop: -10 }} item>
					<p className={classes.conditions} style={{marginBottom: 0}}><Checkbox classes={{root: classes.checkbox}} checked/><span dangerouslySetInnerHTML={{__html: t('popup:intro-integrity')}}></span></p>
				</Grid>
			</Grid> : ''}

			{success ? <Alert severity={"success"}>{t('customer-signup-success')}</Alert> : ''}
		</div>
	);
}
