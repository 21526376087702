import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import theme from "../../../theme";
import { getCMSSettings, getCMSSlot } from "tools/cmsBlockSettings";
import BannerContent from "../text/banner-content";
import Image from 'next/image';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Link from "next/link";
import urlNormalizer from "tools/urlNormalization";
import { useRouter } from "next/router";
import useSessionStore from '@services/stores/SessionStore';

const useStyles = makeStyles({
    grid: {},
    item: {},
    wrapper: {},
    link: {
        position: 'relative',
        background: theme.palette.primary.color,
        display: 'block'
    },
    imageWrapper: {
        opacity: '1 !important',
        transition: 'opacity 0.25s ease',
        '&:hover': {
            opacity: '0.85 !important'
        }
    },
    content: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        paddingBottom: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        pointerEvents: 'none',
        '&:before': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            zIndex: 1,
            width: '100%',
            height: '33%',
            backgroundImage: 'linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0.0))'
        }
    },
    video: {
        display: 'block',
        width: '100%'
    },
	saleIndicator: {
		position: 'absolute',
		top: 20,
		right: 20,
		width: 60,
		height: 60,
		zIndex: 10,
		display: 'none',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '50%',
		color: theme.palette.primary.background,
		fontSize: 13,
		letterSpacing: 1,
		backgroundColor: theme.palette.primary.color
	}
}, { name: 'NewportBarkerTwo' });

export default function NewportBarkerOne({ block, slots, category }) {
    const router = useRouter();
    const classes = useStyles();
    const CMSSettings = getCMSSettings(block);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const text = getCMSSlot('overlay', slots);
    const desktopImage = getCMSSlot('desktop-image', slots);
    const mobileImage = getCMSSlot('mobile-image', slots);
    const config = useSessionStore((state) => state.session.config);

    function getBannerLink(slot) {
        return slot.data.url || '#';
    }

    function getImage(slot) {
        let media = slot.data.media;
        let config = desktopImage.translated.config;

        if (getMediaType(media) === 'image') {
            let returnObject = {
                src: media.url
            };

            returnObject.layout = 'responsive';
            returnObject.width = media.metaData.width;
            returnObject.height = media.metaData.height;

            return <Image {...returnObject} alt={category.metaTitle || category.name || 'Newport image'} className={[classes.image, 'image'].join(' ')} unoptimized/>;
        } else if (media) {
            return <video src={media.url} className={[classes.video].join(' ')} style={{
                opacity: getOpacity(desktopImage),
                objectFit: config.displayMode.value === 'cover' ? 'cover' : 'contain'
            }} autoPlay playsInline loop muted/>;
        }
    }

    function getMediaType(media) {
        return media && media.metaData !== null ? 'image' : 'video';
    }

    function getOpacity(image) {
        return 1;
    }

    function urlNormalize(url) {
        return urlNormalizer(url, router.basePath);
    }

    function renderSaleIndicator() {
		const allowedLanguages = ['sv', 'no', 'fi'];
        const language = config.language.locale;

		return allowedLanguages.indexOf(language) >= 0 ? <div className={[classes.saleIndicator, 'saleIndicator'].join(' ')}>SALE</div> : '';
    }

    return (
        <Grid style={{
            ...CMSSettings.styles,
        }} container>
            <Grid xs={12} className={classes.item} item>
                <div className={classes.wrapper}>
                    <Link href={urlNormalize(getBannerLink(desktopImage))}>
                        <a className={classes.link}>
							{renderSaleIndicator()}
                            <div className={classes.imageWrapper} style={{ opacity: getOpacity(desktopImage) }}>
                                {isMobile ? getImage(mobileImage) : getImage(desktopImage)}
                            </div>
                            {text?.data?.content?.length > 0 ? <div className={classes.content}>
                                <BannerContent content={text.data.content}/>
                            </div> : ''}
                        </a>
                    </Link>
                </div>
            </Grid>
        </Grid>
    );
}
