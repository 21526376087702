import {makeStyles} from "@material-ui/styles";
import {getCMSSettings} from "tools/cmsBlockSettings";
import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import {
	updateRouterData,
	prepareFilters,
	getNewFilterParams
} from '@functions/plpOps';
import ProductFilters from "../../../filters";
import { Button, CircularProgress, Container, Grid, Input, TextField } from "@material-ui/core";
import { useTranslation } from "../../../../tools/i18n";
import SearchIcon from "../../../../public/images/icons/search-thin.svg";
import MasonryProductGrid from "../../../products/masonryProductGrid";
import { ChevronLeft } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import useSessionStore from '@services/stores/SessionStore';

const useStyles = makeStyles((theme) => ({
	container: {
		paddingLeft: 0,
		paddingRight: 0,
		marginBottom: 5
	},
	wrapper: {
		position: 'relative',
		marginTop: 20,
	},
	filterWrapper: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		gap: 0,
		margin: '0px 0 0px',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			gap: 0,
			padding: '0 15px 15px'
		}
	},
	inputWrapper: {
		display: 'flex',
		minHeight: 38,
		justifyContent: 'center',
		[theme.breakpoints.up('sm')]: {
			marginBottom: 30
		}
	},
	inputBg: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		padding: '0px 8px 0 8px',
		backgroundColor: '#f0f0f0',
		[theme.breakpoints.up('sm')]: {
			width: 'auto',
			minWidth: 300,
			padding: '4px 4px 4px 12px',
		},
	},
	input: {
		padding: '6px 0',
		borderRadius: 0,
		backgroundColor: 'transparent',
		'&::placeholder': {
			fontSize: 14,
			opacity: 1,
			color: theme.palette.primary.lowlight,
			fontWeight: 300
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		},
		[theme.breakpoints.up('sm')]: {
			minWidth: 200
		}
	},
	inputRoot: {
		flexGrow: 1
	},
	searchButton: {
		height: 38,
		padding: 4,
		minWidth: 0,
		color: '#000'
	},
	searchIcon: {
		color: '#000'
	},
	backButton: {
		minWidth: 0,
		backgroundColor: '#000',
		color: theme.palette.primary.background,
		borderRadius: 0,
		'&:hover': {
			backgroundColor: theme.palette.primary.lowlight,
			color: theme.palette.primary.background,
		}
	},
	loading: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		opacity: 0.35,
		backgroundColor: '#fafafa'
	}
}), {name: 'SidebarFilter'});

export default function SidebarFilter({category, block, section, cmsPage}) {
	const router = useRouter();
	const { query } = router;
	const classes = useStyles();
	const { t } = useTranslation(['general', 'popup']);
	const CMSSettings = getCMSSettings(block);
	const [categoryData, setCategoryData] = useState({
	  "total": 0,
	  "aggregations": {},
	  "page": 1,
	  "limit": 48,
	  "currentFilters": {
		"navigationId": category.id,
		"manufacturer": [
		  {}
		],
		"price": {
		  "min": 0,
		  "max": 0
		},
		"rating": 0,
		"shipping-free": true,
		"properties": []
	  },
	  "availableSortings": [{
		  key: 'bestseller',
		  translated: {
			  label: 'Topseller'
		  }
	  }],
	  "sorting": false,
	  "elements": []
	});
	const categoryMenu = formatCategoryMenu();

	const currentFilters = categoryData.currentFilters;
	const availableFilters = categoryData.aggregations;

	const session = useSessionStore((state) => state.session);
	const { q, page, order, slug, ...filters } = query;

	const [searchQuery, setSearchQuery] = useState(q || '');
	const [seachMade, setSearchMade] = useState(false);
	const [isLoading, setIsLoading] = useState(true);


	useEffect( async () => {
		let categoryProduct = await fetch('/api/product/get/by-category/' + category.id, {
			headers: {
				'Content-Type': 'application/json',
				'Accepts': 'application/json'
			},
			method: 'GET'
		});

		try {
			const categoryJson = await categoryProduct.json();

			if(categoryJson.category?.elements?.length > 0) {
				categoryJson.category.elements = [];
			}

			setIsLoading(false);

			setCategoryData(categoryJson.category);
		} catch (e) {
			console.log(e);

			setIsLoading(false);
		}

		if(q !== '') {
			await makeSearchQuery();
		}
	}, []);

	useEffect(() => {
		if(searchQuery.length === 0) {
			setSearchMade(false);
		}
	}, [searchQuery]);

	function formatCategoryMenu() {
		const menuItems = category.translated?.customFields?.menu_items;

		return menuItems ? Object.keys(menuItems).map((menuItemId, index) => {
			const menuItem = menuItems[menuItemId];

			return {
				id: menuItemId,
				media: false,
				name: menuItem?.translated?.name,
				order: index,
				parentId: category.id,
				type: 'page',
				url: '/' + (menuItem?.seoUrls?.length > 0 ? menuItem.seoUrls[0].seoPathInfo : 'navigation/' + menuItem.id),
				children: []
			}
		}) : [];
	}

	async function makeSearchQuery() {
		if(!searchQuery || searchQuery.length <= 2) {
			return;
		}

		setIsLoading(true);
		let categoryProduct = await fetch('/api/product/get/by-category/' + category.id + '?query=' + searchQuery , {
			headers: {
				'Content-Type': 'application/json',
				'Accepts': 'application/json'
			},
			method: 'GET'
		});

		try {
			const categoryJson = await categoryProduct.json();

			setCategoryData(categoryJson.category);
			await router.replace(router.asPath.split('?')[0] + '?q=' + searchQuery, undefined, {shallow: true});
		} catch (e) {
			console.log(e);
		}

		setIsLoading(false);
		setSearchMade(true);
	}

	function updateUrlParams({ page, order, slug, ...filters }) {
		const { routerData, replace } = updateRouterData({
			page,
			order,
			initialPage: 0,
			pathname: router.asPath.split('?')[0],
			filters,
			availableFilters
		});

		routerData.pathname = routerData.pathname + '/collections' ;

		if (replace) {
			router.replace(routerData, undefined, { scroll: false }).catch(e => console.log(e));
		} else {
			router.push(routerData, undefined, { scroll: false }).catch(e => console.log(e));
		}
	}

	function onFilterChange(attribute, data) {
		let params = getNewFilterParams(
			page,
			query,
			attribute,
			data,
			availableFilters
		);

		updateUrlParams({...params});
	}

	return (
		<Container maxWidth={'lg'} className={classes.container}>
            <Grid container>
                <Grid xs={12} item>
					<div className={classes.wrapper} style={{
							...CMSSettings.styles,
						}}>

						<div className={classes.filterWrapper}>
							<div className={classes.inputWrapper}>
								{searchQuery ? <Button className={classes.backButton} onClick={() => setSearchQuery('')}><ChevronLeft /></Button> : ''}
								<div className={classes.inputBg}>
									<Input
										type="text"
										placeholder={t('general:sidebar-filter-input', {collection: category.translated.name})}
										value={searchQuery}
										onChange={(event) => {
											setSearchQuery(event.target.value);
										}}
										onKeyDown={(event) => {
											if(event.keyCode === 13) {
												makeSearchQuery();
											}
										}}
										inputProps={{ className: classes.input }}
										classes={{root: classes.inputRoot}}
										disableUnderline={true}
									/>
									<Button className={classes.searchButton} onClick={() => {
											makeSearchQuery();
										}}>
										<SearchIcon className={classes.searchIcon} width={20}/>
									</Button>
								</div>
							</div>

							{!searchQuery ? <ProductFilters
								id={'filters'}
								currentFilters={currentFilters}
								filters={
									prepareFilters(
										availableFilters,
										session.filters,
										{
											data: {
												listing: categoryData
											}
										},
										true
									)
								}
								availableFilters={availableFilters}
								categories={categoryMenu}
								onFilterChange={onFilterChange}
								category={category}
								isMasonry={true}
							/> : ''}

							{isLoading ? <div className={classes.loading}>
								<CircularProgress/>
							</div> : ''}
						</div>

						{searchQuery && categoryData.elements.length > 0 ? <div style={{marginTop: 20}}>
								<MasonryProductGrid products={categoryData.elements} list={'slim-aarons-search'} />
							</div>
						: searchQuery && !isLoading && seachMade ? <Grid xs={12} item><Alert severity="warning">{t('no-suggestions')}</Alert></Grid> : ''}
					</div>
				</Grid>
			</Grid>
		</Container>
	);
}
