import { makeStyles } from "@material-ui/styles";
import { getCMSSettings, getCMSSlot } from "tools/cmsBlockSettings";
import { useTranslation } from "tools/i18n";
import { Button, CircularProgress, Grid, MenuItem, Select, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Image from "next/image";
import React, { useContext, useEffect, useState } from "react";
import EmailValidator from "email-validator";
import { TrackingContext } from "../../../providers/tracking";
import theme from "../../../theme";
import useSessionStore from '@services/stores/SessionStore';
import useUserStore from '@services/stores/UserStore';

const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 30,
        padding: 0,
        gap: 40,
        '&.hasTag': {
            justifyContent: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            gap: 20,
            flexDirection: 'column'
        }
    },
    image: {
        width: '50%',
        '.hasTag &': {
            display: 'none'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    content: {
        '.hasTag &': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    title: {
        marginBottom: 0,
        fontSize: 24,
        fontWeight: 400,
        fontFamily: 'Chronicle, "Open Sans", sans-serif',
        '.hasTag &': {
            display: 'none'
        },
    },
    subtitle: {
        margin: '5px 0 40px',
        fontSize: 12,
        fontWeight: 300,
        '.hasTag &': {
            display: 'none'
        },
    },
    actions: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            '&.showCountrySelector': {
                flexWrap: 'wrap'
            }
        }
    },
    inputWrapper: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    newsletterSelectWrapper: {
        marginRight: 10,
        fontSize: 14,
        backgroundColor: theme.palette.primary.background
    },
    newsletterSelect: {
        padding: '15.5px 14px',
    },
    newsletterInputWrapper: {
        [theme.breakpoints.down('xs')]: {
            '.showCountrySelector &': {
                flexGrow: 1
            }
        }
    },
    newsletterInput: {
        border: '1px solid #cacaca',
        borderRadius: 4,
        backgroundColor: theme.palette.primary.background,
        [theme.breakpoints.up('sm')]: {
            minWidth: 200
        }
    },
    newsletterInputRoot: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 250
        }
    },
    newsletterSignup: {
        marginLeft: 10,
        padding: '6px 34px',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: '1.5px',
        textTransform: 'uppercase',
        color: theme.palette.primary.background,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: '#01011d'
        },
        '.showCountrySelector &': {
            [theme.breakpoints.down('xs')]: {
                height: 50,
                width: '100%',
                marginTop: 10,
                marginLeft: 0,
            }
        },
        '.light &': {
            backgroundColor: '#B99354',
            backgroundImage: 'linear-gradient(180deg, #FBD7A2, #B99354)',
            color: '#16243C'
        }
    },
    newsletterInputLabel: {
        fontSize: 14
    }
}, { name: 'NewsletterSignup' });

export default function NewsletterSignup({ slots, block }) {
    const classes = useStyles();
    const CMSSettings = getCMSSettings(block);
    const slot = getCMSSlot('newsletterSignup', slots);
    const { t } = useTranslation(['popup', 'errors']);
    const { token, salesChannel, config, countries } = useSessionStore((state) => state.session);
    const { getCountries } = useSessionStore((state) => state.methods);
    const myNewportService = useUserStore((state) => state.service);
    const { actions: trackingActions } = useContext(TrackingContext);
    const [newsletterEmail, setNewsletterEmail] = useState('');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [showCountrySelector, setShowCountrySelector] = useState(false);
    const [country, setCountry] = useState(false);
    const salesChannelId = salesChannel.id;
    const countryId = salesChannel.countryId;

    useEffect(() => {
        if (token && config.language.locale === 'en') {
            getCountries();
        }
    }, [token]);

    useEffect(() => {
        if (countries && countries.length > 1) {
            setShowCountrySelector(true);
            setCountry(getInitialSalesChannelCountry());
        }
    }, [countries]);

    function getInitialSalesChannelCountry() {
        let defaultCountry = countries.filter((country) => {
            return country.id === countryId;
        });

        return defaultCountry.length > 0 ? defaultCountry[0].iso : countries[0].iso;
    }

    function validateEmail(email) {
        return EmailValidator.validate(email);
    }

    async function newsletterSubmit() {
        if (!validateEmail(newsletterEmail)) {
            return;
        }

        let subscriberCountry = country ? country : config?.language?.languageCode.split('-')[1];

        try {
            setLoading(true);

            let body = {
                email: newsletterEmail,
                country: subscriberCountry,
                store: salesChannelId,
                source: 'event',
            }

            const discoveryKey = myNewportService.getLocalDiscoveryKey();
            if (discoveryKey) {
                body.discovery_key = discoveryKey;
            }

            if(hasTag()) {
                body.tags = [slot.config.tag.value];
                if (slot.config.tag.value === 'custom') {
                    body.tags = ['custom', slot?.config?.customTag?.value];
                }
            }

            if (hasStoreId()) {
                body.event_store_id = slot?.config?.eventStoreId?.value;
            }

            let response = await fetch('/api/voyado/newsletter', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });

            let registrationData = await response.json();

            if (registrationData.status === 'error') {
                if (registrationData.message.indexOf('Member exists') >= 0) {
                    setError(t('errors:signup-already-exists'));
                } else if (registrationData.message.indexOf('Request is invalid') >= 0) {
                    setError(t('errors:signup-request-invalid'));
                } else {
                    setError(registrationData.message);
                }
            } else {
                setError(false);
                setNewsletterEmail('');
                setSuccess(true);

                trackingActions.click({
                    type: 'footer_newsletter',
                    text: t('popup:intro-subscribe'),
                    identifier: 'signup_success'
                });
            }
            setLoading(false);
        } catch (e) {
            setError(t('errors:signup-general-error'));

            setLoading(false);
        }
    }

    function hasTag() {
        return slot.config.tag.value !== '';
    }

    function hasStoreId() {
        return Number.isInteger(parseInt(slot?.config?.eventStoreId?.value, 10));
    }

    return (
        <div className={[classes.wrapper, hasTag() || true ? 'hasTag' : '', CMSSettings.class].join(' ')} style={{
            ...CMSSettings.styles,
        }}>
            <div className={classes.image}>
                <Image src={'/images/popup/pop-up-800-540.jpg'} width={800} height={540}/>
            </div>
            <div className={classes.content}>
                <h3 className={classes.title}>{t('popup:newsletter-signup-title')}</h3>
                <p className={classes.subtitle}>{t('popup:newsletter-signup-text')}</p>
                {error ? (
                    <Grid item>
                        <Alert severity="error" style={{ marginBottom: 10 }}>
                            <span dangerouslySetInnerHTML={{ __html: error }}/>
                        </Alert>
                    </Grid>
                ) : (
                    ''
                )}

                <div className={[classes.actions, 'showCountrySelector'].join(' ')}>
                    <div className={classes.inputWrapper}>
                        {showCountrySelector ?
                            <Select
                                value={country}
                                onChange={(event) => setCountry(event.target.value)}
                                classes={{
                                    select: classes.newsletterSelect
                                }}
                                className={classes.newsletterSelectWrapper}
                                variant={'outlined'}
                                size={'small'}
                                autoWidth={true}>
                                {countries.sort((a, b) => {
                                    if (a.iso < b.iso) {
                                        return -1;
                                    }

                                    if (a.iso > b.iso) {
                                        return 1;
                                    }
                                }).map((country) => <MenuItem value={country.iso}>{country.translated?.name}</MenuItem>)}
                            </Select>
                            : ''}

                        <TextField
                            type="email"
                            variant={'filled'}
                            size={'small'}
                            label={t('popup:intro-email-placeholder')}
                            className={classes.newsletterInputWrapper}
                            classes={{root: classes.newsletterInputRoot}}
                            error={!!newsletterEmail && !validateEmail(newsletterEmail)}
                            value={newsletterEmail}
                            onChange={event => setNewsletterEmail(event.target.value)}
                            inputProps={{ className: classes.newsletterInput }}
                            InputProps={{ disableUnderline: true }}
                            InputLabelProps={{ className: classes.newsletterInputLabel }}
                        />
                    </div>
                    <Button className={classes.newsletterSignup} onClick={() => newsletterSubmit()}>
                        <>
                            {t('popup:intro-subscribe')}
                            {loading ? <CircularProgress size={14} color={'inherit'} style={{ marginLeft: 8 }}/> : ''}
                        </>
                    </Button>
                </div>

                {success ? <Alert severity={"success"} style={{ marginTop: 10 }}>{hasTag() ? t('popup:newsletter-signup-tag-success') : t('popup:newsletter-signup-success')}</Alert> : ''}
            </div>
        </div>
    );
}
