import {Button, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {getCMSSettings} from "tools/cmsBlockSettings";
import Image from 'next/image';
import Link from "next/link";
import theme from "../../../theme";
import urlNormalizer from "tools/urlNormalization";
import {useRouter} from "next/router";
import Slick from "react-slick";
import {ChevronLeft, ChevronRight} from "@material-ui/icons";

const useStyles = makeStyles({
	wrapper: {
		display: 'flex',
		position: 'relative',
		gap: 20,
		'& .regular': {
			display: 'flex',
			flexWrap: 'wrap',
			width: '100%',
			gap: 20
		},
		'& .slick-slider': {
			width: '100%',
			padding: '0 20px',
			overflow: 'hidden',
		},
		'& .slick-track': {
			display: 'flex',
			gap: 10,
			transform: 'translate3d(0,0,0)'
		},
		'& .slick-slide': {
			display: 'flex',
			'& > div': {
				display: 'flex',
				width: '100%'
			}
		},
		'& .slick-list': {
			marginBottom: 25,
			maxWidth: '100vw',
			transform: 'translate3d(0,0,0)'
		},
		[theme.breakpoints.down('sm')]: {
			gap: 15,
			'& .slick-slider': {
				paddingBottom: 12
			},
			'& .slick-slide': {
				padding: 0
			},
			'& .slick-list': {
				marginBottom: 30
			}
		},
	},
	grid: {
		[theme.breakpoints.down('xs')]: {
			marginBottom: '20px !important'
		}
	},
	imageWrapper: {
		position: 'relative',
		background: theme.palette.primary.color,
		display: 'block',
		transition: 'opacity 0.25s ease',
		'&:hover': {
			opacity: 0.9
		},
		'& .gradient' : {
			content: '""',
			position: 'absolute',
			inset: 0,
			background: 'linear-gradient(0deg, rgba(0,0,0,0.45) 0%, rgba(0,0,0,0) 45%)'
		}
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		justifyContent: 'space-between',
		padding: '15px 20px 20px',
		backgroundColor: theme.palette.primary.background,
		textAlign: 'center',
		'.no-slider &': {
			[theme.breakpoints.down('sm')]: {
				padding: '20px 8px',
			}
		},
		'.no-padding &': {
			[theme.breakpoints.down('sm')]: {
				padding: '0 !important',
			}
		}

	},
	preTitle: {
		display: 'none',
		margin: 0,
		fontSize: 12,
		fontWeight: 300,
		color: theme.palette.primary.highlight,
		textTransform: 'uppercase',
		letterSpacing: 2
	},
	title: {
		margin: 0,
		fontWeight: 400,
		fontSize: 24,
		color: theme.palette.primary.lowlight,
		fontFamily: 'Chronicle, serif',
		[theme.breakpoints.down('sm')]: {
			fontSize: 18,
		},
		'.small-title &': {
			fontFamily: 'Futura',
			fontSize: 14
		}
	},
	description: {
		margin: 0,
		marginTop: 6,
		fontSize: 15,
		fontWeight: 400,
		color: theme.palette.primary.color,
		'& p': {
			margin: 0
		}
	},
	item: {
		display: 'flex !important',
		width: 'calc(50% - 40px)',
		'.no-slider &': {
			[theme.breakpoints.down('sm')]: {
				width: 'calc(50% - 7.5px)',
			}
		},
		[theme.breakpoints.up('md')]: {
			width: 'calc(25% - 15px)'
		}
	},
	itemLink: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		color: 'inherit',
		textDecoration: 'none'
	},
	arrow: {
		position: 'absolute',
		bottom: 0,
		zIndex: 10,
		minWidth: 0,
		backgroundColor: theme.palette.primary.background,
		boxShadow: '3px 3px 3px rgba(50, 50, 93, 0.04)'
	},
	arrowRight: {
		right: 0,
	},
	arrowLeft: {
		left: 0,
	},
	arrowIcon: {
		display: 'block'
	},
	slickDots: {
		display: 'flex !important',
		justifyContent: 'center',
		padding: 0,
		listStyle: 'none',
		'& li': {
			width: 5,
			height: 5,
			margin: '2px 4px',
			borderRadius: '50%',
			backgroundColor: 'rgba(0,0,0,0.25)',
			'&.slick-active': {
				width: 6,
				height: 6,
				backgroundColor: 'rgba(0,0,0,0.75)'
			},
			'& button': {
				display: 'none'
			}
		},
		[theme.breakpoints.down('sm')]: {
			margin: 0
		}
	}
}, {name: 'BarkerEight'});

export default function BarkerEight({block, slots, category}) {
	const router = useRouter();
	const classes = useStyles();
	const CMSSettings = getCMSSettings(block);

	const settings = {
		dots: false,
		infinite: false,
		nextArrow: <Button classes={{root: [classes.arrow, classes.arrowRight].join(' ')}}><ChevronRight classes={{root: classes.arrowIcon}}/></Button>,
		prevArrow: <Button classes={{root: [classes.arrow, classes.arrowLeft].join(' ')}}><ChevronLeft classes={{root: classes.arrowIcon}}/></Button>,
		dotsClass: classes.slickDots,
		responsive: [
			{
				breakpoint: 9999,
				settings: "unslick"
			},
			{
				breakpoint: theme.breakpoints.values.md,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					rows: 1,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: theme.breakpoints.values.sm,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					rows: 1,
					infinite: true,
					dots: true
				}
			}
		]
	};

	function getImage(slot) {
		let media = slot.data?.media;

		if(!media) {
			return {
				src: ' ',
				width: 0,
				height: 0
			}
		}

		let returnObject = {
			src: media.url
		};

		returnObject.layout = 'responsive';
		returnObject.width = media.metaData.width;
		returnObject.height = media.metaData.height;

		return returnObject;
	}

	function getActiveSliders() {
		return slots.filter((slide) => {
			if(slide.type === 'display-settings') {
				return false;
			}

			return slide.config.active?.value !== false;
		});
	}

	function urlNormalize(url) {
		return urlNormalizer(url, router.basePath);
	}

	function getIsNoSlider() {
		return block.type === 'barker-eight-no-slider';
	}

	function renderItems() {
		return getActiveSliders().map((item, key) => {
			return (
				<div className={classes.item} key={item.blockId + '-' + key}>
					<Link href={urlNormalize(item.translated.config.url.value)}>
						<a className={classes.itemLink} key={item.blockId}>
							<div className={classes.imageWrapper}>
								<Image {...getImage(item)} className={[classes.image, 'image'].join(' ')} alt={category.metaTitle || category.name || 'Newport image'} loading={'lazy'} unoptimized/>
								{ !item?.translated?.config?.preTitle?.value && !item?.translated?.config?.title?.value && !item?.translated?.config?.description?.value ?
									<div className={'gradient'}></div> : ''
								}
							</div>
							{ item?.translated?.config?.preTitle?.value || item?.translated?.config?.title?.value || item?.translated?.config?.description?.value ?
								<div className={classes.content}>
									<div>
										<p className={classes.preTitle}>{item.translated.config.preTitle.value}</p>
										<h4 className={classes.title}>{item.translated.config.title.value}</h4>
									</div>
									{item.translated.config.description?.value ? <div className={classes.description} dangerouslySetInnerHTML={{__html: item.translated.config.description.value}}/> : ''}
								</div>
								: ''
							}
						</a>
					</Link>
				</div>
			)
		})
	}

	return (
		<Grid style={{
				...CMSSettings.styles,
			}} className={[classes.grid, block.cssClass].join(' ')} container>
			<Grid xs={12} item>
				<Grid className={[classes.wrapper, getIsNoSlider() ? 'no-slider' : ''].join(' ')} container>
					{
						getIsNoSlider() ? renderItems() :
						<Slick {...settings}>
							{renderItems()}
						</Slick>
					}
				</Grid>
			</Grid>
		</Grid>
	);
}
