import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import theme from "../../../theme";
import ChevronRight from "../../../../public/images/icons/chevron_right_light.svg";
import ChevronLeft from "../../../../public/images/icons/chevron_left_light.svg";
import {getCMSSettings, getCMSSlot} from "tools/cmsBlockSettings";
import Image from 'next/image';
import Link from "next/link";
import { useEffect, useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles({
	wrapper: {
		margin: 0,
		position: 'relative',
		'&.hide': {
			height: 0,
			opacity: 0,
			pointerEvents: 'none',
		}
	},
	slider: {
		position: 'relative',
		width: '100%',
		height: 'auto',
		aspectRatio: '24 / 10',
		marginBottom: 20,
		display: 'flex',
		overflow: 'auto',
		'& .slide': {
			opacity: 0,
			transition: 'opacity 0.5s ease',
			display: 'block',
			zIndex: 1,
		},
		'& .activeSlide': {
			opacity: 1,
			zIndex: 2,
		},
		[theme.breakpoints.down('sm')]: {
			aspectRatio: '3 / 4',
		}
	},
	image: {
		width: '100vw',
		height: '100%',
		flexShrink: 0,
	},

	arrows: {
		position: 'absolute',
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		top: '50%'
	},
	arrow: {
		position: 'absolute',
		top: '45%',
		zIndex: 10,
		minWidth: 0,

		backgroundColor: theme.palette.primary.background,
		boxShadow: theme.boxShadow[1],
		borderRadius: '4px',
		'&:hover': {
			backgroundColor: theme.palette.primary.background,
			boxShadow: theme.boxShadow[2],
		}
	},
	arrowRight: {
		right: 5,
		[theme.breakpoints.up('sm')]: {
			right: 15
		}
	},
	arrowLeft: {
		left: 5,
		[theme.breakpoints.up('sm')]: {
			left: 15
		}
	},
	arrowIcon: {
		display: 'block'
	},
	slickDots: {
		display: 'flex !important',
		justifyContent: 'center',
		padding: 0,
		listStyle: 'none',
		'& li': {
			width: 6,
			height: 6,
			margin: '2px 4px',
			borderRadius: '50%',
			backgroundColor: 'rgba(0,0,0,0.25)',
			'&.slick-active': {
				width: 6,
				height: 6,
				backgroundColor: 'rgba(0,0,0,0.75)'
			},
			'& button': {
				display: 'none'
			}
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: 0,
			marginBottom: -4
		}
	}
}, {name: 'ImageSlider'});

export default function ImageHeroCarousel({block, slots}) {
	const CMSSettings = getCMSSettings(block);
	const classes = useStyles();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
	 noSsr: true
	});
	const slider = getCMSSlot('carousel', slots);
	const slotSettings = slider?.translated?.config
	const [slides, setSlides] = useState({
		activeIndex: 0,
		numOfSlides: 0,
		slides: []
	});

	useEffect(() => {
		const deviceSlides = slider.config.sliderItems.value.filter(item => {
			return isMobile ? item.size === 'mobile' : item.size === 'desktop'
		})

		const current = {
			activeIndex: slides.activeIndex,
			numOfSlides: deviceSlides.length,
			slides: deviceSlides
		}
		setSlides(current);
	}, [slider?.config?.sliderItems, isMobile]);

	function getImage(image, current, index) {

		let imageObject = {
			src: image.mediaUrl,
			alt: image.altText || ''
		};

		imageObject.layout = 'fill';

		return (
			<Link href={image.url}>
				<a key={index} className={['slide', current ? 'activeSlide' : ''].join(' ')}>
					<Image {...imageObject} className={[classes.image, 'image', 'slide-'+ index].join(' ')}   unoptimized/>
				</a>
			</Link>
		)
	}

	function switchImageLeft() {
		slides.activeIndex === 0 ?
			setSlides({...slides, activeIndex: slides.numOfSlides - 1})
			:
			setSlides({...slides, activeIndex: slides.activeIndex - 1})
	}

	function switchImageRight() {
		slides.activeIndex === slides.numOfSlides - 1 ?
			setSlides({...slides, activeIndex: 0})
			:
			setSlides({...slides, activeIndex: slides.activeIndex + 1})
	}


	return (
		<div className={[classes.wrapper, slides?.slides?.length === 0 ? 'hide' : ''].join(' ')} style={{ ...CMSSettings.styles}}>
			<section className={classes.slider}>
				{ slides?.slides?.length > 0 && slides.slides.map((image, index) => {
						const current = (index === slides.activeIndex)
						return getImage(image, current, index)
					})
				}
			</section>
			<Button classes={{root: [classes.arrow, classes.arrowLeft].join(' ')}} onClick={switchImageLeft}>
				<ChevronLeft width={28} className={classes.arrowIcon}/>
			</Button>
			<Button classes={{root: [classes.arrow, classes.arrowRight].join(' ')}} onClick={switchImageRight}>
				<ChevronRight width={28} className={classes.arrowIcon}/>
			</Button>
		</div>
	);
}
