import { makeStyles } from "@material-ui/styles";
import { getCMSSettings, getCMSSlot } from "tools/cmsBlockSettings";
import React, { useEffect, useState } from "react";
import { useTranslation } from "../../../../tools/i18n";
import { Collapse, Grid } from "@material-ui/core";
import TextContent from "../text/text-content";
import ExpandMore from "../../../../public/images/icons/expand_more_light.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../../../theme";
import useSessionStore from '@services/stores/SessionStore';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        [theme.breakpoints.up('md')]: {
            maxWidth: '85%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    grid: {
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center'
        }
    },
    title: {
        margin: 0,
        fontSize: 12,
        fontWeight: 500,
        textTransform: 'uppercase',
        [theme.breakpoints.up('sm')]: {
            fontSize: 14
        }
    },
    openingHour: {
        margin: 0,
        lineHeight: 1.75,
        fontSize: 12,
        fontWeight: 300,
        color: '#000',
        '& .dayOfWeek': {
            textTransform: 'capitalize',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 14
        }
    },
    dayOfWeek: {
        textTransform: 'capitalize',
        '&:after': {
            content: '" - "'
        }
    },
    storeStatus: {
        fontWeight: 600,
        '&.open': {
            color: 'green'
        },
        '&.closed': {
            color: 'red'
        },
        '&:after': {
            content: '" - "'
        }
    },
    accordionWrapper: {
        display: "flex",
        justifyContent: 'flex-start',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'center'
        }
    },
    accordion: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    accordionIcon: {
        margin: '-16px -16px -16px 0',
        transform: 'scale(0.5)'
    },
    collapseWrapper: {
        marginTop: 10
    },
    googleMapsLink: {
        color: 'inherit',
        lineHeight: 1.75,
        fontSize: 12,
        fontWeight: 300,
        [theme.breakpoints.up('sm')]: {
            fontSize: 14
        }
    },
    contact: {
        '& a': {
            textDecoration: 'none'
        }
    }
}), { name: 'StoreInfo' });

export default function StoreInfo({ slots, block }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const CMSSettings = getCMSSettings(block);
    const config = useSessionStore((state) => state.session.config);
    const slot = getCMSSlot('storeInfo', slots);
    const [accordionOpen, setAccordionOpen] = useState(false);
    const [googleMapsUrl, setGoogleMapsUrl] = useState('');
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const translatedConfig = slot.translated.config;
    const daysMap = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

    useEffect(() => {
        let googleMapsUrl = getGoogleMapsLink(translatedConfig.address.value);

        if(googleMapsUrl) {
            setGoogleMapsUrl(googleMapsUrl);
        }
    }, []);

    function getRenderedOpeningHours() {
        return daysMap.map((day, dayIndex) => {
            dayIndex = dayIndex + 1;
            if(dayIndex > 6) {
                dayIndex = 0;
            }

            let now = new Date('Wed Apr 26 2023 08:53:16 GMT+0200 (Central European Summer Time)');
            let daysUntilDay = now.getDay() <= dayIndex ? dayIndex - now.getDay() : (dayIndex + 7) - now.getDay();
            let dayOfWeek = new Date().setDate(now.getDate() + daysUntilDay);
            let timeString = translatedConfig.openingHours.value[day];

            if(!timeString) {
                return <p className={classes.openingHour}>
                    <span className={classes.dayOfWeek}>{new Intl.DateTimeFormat(config.language.languageCode, { weekday: 'long' }).format(dayOfWeek)}</span>
                    {t('store-closed')}
                </p>
            }

            let fromTime = timeString.split(' - ')[0] || '10.00';
            let toTime = timeString.split(' - ')[1] || '18.00';

            return (
                <p className={classes.openingHour}>
                    <span className={classes.dayOfWeek}>{new Intl.DateTimeFormat(config.language.languageCode, { weekday: 'long' }).format(dayOfWeek)}</span>
                    {fromTime} - {toTime}
                </p>
            );
        })
    }

    function checkIsTimesStringActive(timeString, dayIndex) {
        let day = dayIndex + 1;
        if(day > 6) {
            day = 0;
        }


        let now = new Date();
        let daysUntilDay = now.getDay() <= day ? day - now.getDay() : (day + 7) - now.getDay();
        let dayOfWeek = new Date(now);
        dayOfWeek.setDate(now.getDate() + daysUntilDay);

        let fromTime = new Date(dayOfWeek);
        fromTime.setHours(timeString.split(' - ')[0]?.split('.')[0] || 10);
        fromTime.setMinutes(timeString.split(' - ')[0]?.split('.')[1] || 0);
        fromTime.setSeconds(0);

        let toTime = new Date(dayOfWeek);
        toTime.setHours(timeString.split(' - ')[1]?.split('.')[0] || 18);
        toTime.setMinutes(timeString.split(' - ')[1]?.split('.')[1] || 0);
        toTime.setSeconds(0);

        if(now.getTime() > fromTime.getTime() && now.getTime() < toTime.getTime()) {
            return { status: 'isActive', dayOfWeek };
        } else if(now.getTime() < fromTime.getTime()) {
            return { status: 'isBefore', dayOfWeek };
        } else {
            return { status: 'isAfter', dayOfWeek }
        }
    }

    function getOpenStatus() {
        let now = new Date();
        let dayIndex = now.getDay() - 1;
        if(dayIndex < 0) {
            dayIndex = 6;
        }

        let timeString = translatedConfig.openingHours.value[daysMap[dayIndex]];

        if(!timeString) {
            return false;
        }

        if(checkIsTimesStringActive(timeString, dayIndex).status === 'isActive') {
            return {day: daysMap[dayIndex], index: dayIndex};
        } else {
            return false;
        }
    }

    function getNextOpening() {
        let now = new Date();
        let dayIndex = now.getDay() - 1;
        if(dayIndex < 0) {
            dayIndex = 6;
        }

        let foundTimeString = false;
        let maxIterations = 7;
        let iteration = 0;

        do {
            let timeString = translatedConfig.openingHours.value[daysMap[dayIndex]];

            if(timeString) {
                let stringActiveCheck = checkIsTimesStringActive(timeString, dayIndex);

                if(stringActiveCheck.status === 'isBefore') {
                    foundTimeString = {
                        day: daysMap[dayIndex],
                        dayOfWeek: stringActiveCheck.dayOfWeek,
                        timeString
                    };
                }
            }

            dayIndex++;

            if(dayIndex > 6) {
                dayIndex = 0;
            }

            iteration++;
        } while (!foundTimeString && iteration < maxIterations);

        return foundTimeString;
    }

    function renderOpenStore() {
        let status = getOpenStatus();

        if(status) {
            let {day} = status;
            let timeString = translatedConfig.openingHours.value[day];
            let toTime = timeString.split(' - ')[1] || '18.00';

            return (
                <p className={classes.openingHour}><span className={[classes.storeStatus, 'open'].join(' ')}>{t('store-open')}</span> {t('store-closing', {time: toTime})}</p>
            );
        } else {
            let nextOpening = getNextOpening();

            if(nextOpening) {
                let {dayOfWeek, timeString} = nextOpening;
                let fromTime = timeString.split(' - ')[0] || '10.00';

                return (
                    <p className={classes.openingHour}>
                        <span className={[classes.storeStatus, 'closed'].join(' ')}>{t('store-closed')}</span>
                        <span dangerouslySetInnerHTML={{__html: t('store-opening', {day: new Intl.DateTimeFormat(config.language.languageCode, { weekday: 'long' }).format(dayOfWeek), time: fromTime})}}/>
                    </p>
                );
            }
        }
    }

    function getGoogleMapsLink(address) {
        let tempDiv = document.createElement("div");
        tempDiv.innerHTML = address;

        let addressText = tempDiv.textContent || tempDiv.innerText || '';
        let addressUrlSafe = encodeURIComponent(addressText.replace(/\n/g, " ").trim());

        return 'https://www.google.com/maps/search/?api=1&query=' + addressUrlSafe;
    }

    return (
        <div style={{
            ...CMSSettings.styles,
        }}
             className={[classes.wrapper, block.cssClass].join(' ')}
        >
            <Grid className={classes.grid} spacing={isMobile ? 2 : 4} container>
                <Grid xs={6} item>
                    <h4 className={classes.title}>{t('store-opening-hours')}</h4>
                    <div className={classes.accordionWrapper}>
                        <div className={classes.accordion} onClick={() => setAccordionOpen(!accordionOpen)}>
                            {renderOpenStore()}
                            <ExpandMore width={48} height={48} className={classes.accordionIcon}/>
                        </div>
                    </div>
                    <Collapse in={accordionOpen} className={classes.collapseWrapper}>
                        {getRenderedOpeningHours()}
                    </Collapse>
                </Grid>
                <Grid xs={6} item>
                    <h4></h4>
                    <TextContent content={translatedConfig.openingHoursExemptions.value} newStyling={true}/>
                </Grid>
                <Grid xs={12} sm={6} item>
                    <h4 className={classes.title}>{t('store-address')}</h4>
                    <TextContent content={translatedConfig.address.value} newStyling={true}/>
                    <a href={googleMapsUrl} target={"_blank"} className={classes.googleMapsLink}>{t('google-maps-open')}</a>
                </Grid>
                <Grid xs={12} sm={6} item>
                    <h4 className={classes.title}>{t('store-contact')}</h4>
                    <TextContent content={translatedConfig.contact.value} className={classes.contact} newStyling={true}/>
                </Grid>
            </Grid>
        </div>
    );
}
