import { default as Text } from './text/text';
import { default as TextVariant } from './text/text-variant';
import { default as TextTwoColumns } from './text/two-columns';
import { default as TextThreeColumns } from './text/three-columns';
import { default as MainBanner } from './text-and-image/newport-main-banner';
import { default as ChosenProduct } from './commerce/newport-chosen-product';
import { default as ProductBlock } from './commerce/newport-product-block';
import { default as BarkerSix } from './text-and-image/barker-six';
import { default as BarkerEight } from './text-and-image/barker-eight';
import { default as BarkerTwo } from './text-and-image/barker-two';
import { default as BarkerOne } from './text-and-image/barker-one';
import { default as ImageSideText } from './text-and-image/image-side-text';
import { default as ImageTextSlider } from './text-and-image/image-text-slider';
import { default as ParallaxImage } from './text-and-image/parallax';
import { default as CampaignEntry } from './text-and-image/campaign-entry';
import { default as CategoryNavigation } from './commerce/category-navigation';
import { default as Image } from './image/image';
import { default as ImageSlider } from './image/image-slider';
import { default as ImageSliderLightbox } from './image/image-slider-lightbox';
import { default as YoutubeVideo } from './video/youtube';
import { default as MainInspirationFeed } from './inspiration/main-feed';
import { default as ZendeskForm } from './commerce/zendesk-form';
import { default as CustomerSignup } from './form/customer-signup';
import RSVPForm from './form/rsvp-form';
import { default as BarkerFour } from './text-and-image/barker-four';
import { default as CmsButton } from './buttons/cms-button';
import StoreBrowser from "./misc/store-browser";
import StoreInfo from "./misc/store-info";
import GoogleMaps from "./misc/google-maps";
import StoreProducts from "./misc/store-products";
import ButtonGrid from "./buttons/button-grid";
import SlimAaronsBarker from "./text-and-image/slim-aarons-barker";
import MainBannerNew from "./text-and-image/main-banner-new";
import NewsletterSignup from "./form/newsletter-signup";
import SidebarFilter from "./commerce/sidebar-filter";
import CategoryMenu from "./menu/category-menu";
import VoyadoProductBlock from "./commerce/voyado-product-block";
import FancyNewsletterSignup from "./form/fancy-newsletter-signup";
import InspirationPageFilters from "./inspiration/inspiration-page-filter";
//import FrontpageBarker from "./commerce/frontpage-barker";
import ImageHeroCarousel from '@components/cms/blocks/image/image-hero-carousel';

export default {
    'newport-main-banner': MainBanner,
    'main-banner-new': MainBannerNew,
    'newport-chosen-product': ChosenProduct,
    'newport-product-slider': ProductBlock,
    'newport-campaign-entry': CampaignEntry,
    'newport-image': Image,
    'image-slider': ImageSlider,
    'image-slider-lightbox': ImageSliderLightbox,
    'image-hero-carousel': ImageHeroCarousel,
    'barker-four': BarkerFour,
    'barker-two': BarkerTwo,
    'barker-one': BarkerOne,
    'barker-six': BarkerSix,
    'barker-six-no-slider': BarkerSix,
    'barker-eight': BarkerEight,
    'barker-eight-no-slider': BarkerEight,
    'left-image-side-text': ImageSideText,
    'right-image-side-text': ImageSideText,
    'image-text-slider': ImageTextSlider,
    'newport-parallax-image': ParallaxImage,
    'youtube-video': YoutubeVideo,
    'vimeo-video': YoutubeVideo,
    'text': Text,
    'slim-aarons-barker': SlimAaronsBarker,
    'text-two-column': TextTwoColumns,
    'text-three-column': TextThreeColumns,
    'newport-text-variant-1': TextVariant,
    'newport-text-variant-2': TextVariant,
    'newport-text-variant-3': TextVariant,
    'category-navigation': CategoryNavigation,
    'category-menu': CategoryMenu,
    'main-inspiration-feed': MainInspirationFeed,
    'zendesk-forms': ZendeskForm,
    'customer-signup': CustomerSignup,
    'newsletter-signup': NewsletterSignup,
    'fancy-newsletter-signup': FancyNewsletterSignup,
    'rsvp-signup': RSVPForm,
    'cms-button': CmsButton,
    'button-grid': ButtonGrid,
    'store-browser': StoreBrowser,
    'store-info': StoreInfo,
    'google-maps': GoogleMaps,
    'store-products': StoreProducts,
    'sidebar-filter': SidebarFilter,
    'voyado-product-block': VoyadoProductBlock,
    'newport-inspiration-filter': InspirationPageFilters
    //'frontpage-barker': FrontpageBarker
};
